<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
          <div class="d-flex align-items-center flex-wrap mr-1">
            <h5 class="text-dark font-weight-bold my-2 mr-5 text-capitalize">
              <span>{{ title }}</span>
            </h5>

            <TopSearchbar
              v-if="isSearchActivated"
              :searchTitle.sync="searchTitle"
            ></TopSearchbar>

            <ul
              v-else
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
            >
              <li class="breadcrumb-item">
                <router-link
                  :to="getDefaultRoute('dashboard')"
                  class="subheader-breadcrumbs-home"
                >
                  <span class="svg-icon svg-icon-lg">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/dashboard.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </router-link>
              </li>
              <i
                class="mdi mdi-chevron-right"
                v-if="breadcrumbs.length > 1"
              ></i>
              <template v-for="(breadcrumb, i) in breadcrumbs">
                <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
                  <router-link
                    v-if="breadcrumb.route"
                    :key="i"
                    :to="
                      getDefaultRoute(breadcrumb.route, {
                        query: { status: 'all' },
                      })
                    "
                    class="text-muted"
                  >
                    <span v-if="breadcrumb.barcode">{{
                      breadcrumb.barcode
                    }}</span>
                    <span v-else>{{ breadcrumb.title }}</span>
                  </router-link>
                  <i v-if="!breadcrumb.route" class="mdi mdi-chevron-right"></i>
                  <span
                    class="text-muted text-capitalize"
                    :key="i"
                    v-if="!breadcrumb.route"
                  >
                    <span v-if="breadcrumb.barcode">{{
                      breadcrumb.barcode
                    }}</span>
                    <span v-else>{{ breadcrumb.title }}</span>
                  </span>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <!-- end:: Header Menu -->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import TopSearchbar from "@/view/layout/header/TopSearchbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { SearchEventBus } from "@/core/lib/search.lib";

export default {
  name: "KTHeader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  data: () => ({
    isSearchActivated: false,
    searchTitle: null,
  }),
  components: {
    KTTopbar,
    TopSearchbar,
  },
  methods: {
    getMenuTitle(title) {
      return title;
    },
  },
  beforeCreate() {
    const _this = this;
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      _this.$refs["kt_header_menu"],
      _this.$refs["kt_header_menu_wrapper"]
    );

    SearchEventBus.$on("search-template", (argument) => {
      _this.isSearchActivated = argument;
    });

    SearchEventBus.$on("search-module-title", (argument) => {
      _this.searchTitle = _this.lodash.capitalize(
        _this.lodash.lowerCase(argument)
      );
    });
  },
  beforeDestroy() {
    SearchEventBus.$off("search-template");
    SearchEventBus.$off("search-module-title");
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
