var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.MenuList),function(row,index){return [_c('router-link',{key:index,class:{
        'menu-item-active': _vm.isRouteActive(row.menu),
        'disable-link': _vm.disableLink(row.menu),
      },attrs:{"to":row.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-custom-menu-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + row.icon + '.svg')}})],1),(row.menu != 'dashboard' && row.menu != 'calendar')?_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.getMenuTitle(row.title)))]):_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(row.title))])])])]}}],null,true)})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }